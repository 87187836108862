/* eslint-disable */
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export type Gender = "male" | "female";
export type Unit = "kg" | "lb" | "cm" | "in" | "kg/cm" | "lb/in";
export type Dictionary = {
  [key: string]: any;
};

export interface CalculationInputs {
  isMale: boolean;
  dob?: string;
  dom?: string;
  measurementA: number;
  measurementB?: number;
  ageRange: number[];
  isUnitMetric: boolean;
}

export interface APC {
  ageMonths: number;
  L: number;
  M: number;
  S: number;
}

export interface Labels {
  name: string;
  description: string;
  dob: string;
  dom: string;
  measurementAName: string;
  measurementAUnit: string;
  measurementBName?: string;
  measurementBUnit?: string;
}

export interface DefaultValues {
  age: number; // in years
  hasDates: boolean;
  ageRange: number[] | null; // in years
  isMale: boolean;
  measurementA: number;
  measurementB?: number;
  isUnitMetric: boolean;
}

interface CalculateResponse {
  success: boolean;
  data?: {
    isMale: boolean;
    percentile: number;
    chart: Dictionary;
  };
  error?: string;
}

export class WHOCalculator {
  protected defaultValues: DefaultValues = {
    age: 1, // in years
    hasDates: true,
    ageRange: [0, 2], // in years
    isMale: true,
    measurementA: 0,
    isUnitMetric: true
  };

  protected labels: Labels = {
    name: "",
    description: "",
    dob: "Fecha de nacimiento",
    dom: "Fecha de medición",
    measurementAName: "",
    measurementAUnit: ""
  };

  public getLabels(): Labels {
    return this.labels;
  }

  public getDefaultValues() {
    return {
      isMale: this.defaultValues.isMale,
      ageRange: this.defaultValues.hasDates
        ? this.defaultValues.ageRange
        : null,
      dob: this.defaultValues.hasDates
        ? moment()
            .subtract(this.defaultValues.age, "years")
            .add(1, "day")
            .format(DATE_FORMAT)
        : null,
      dom: this.defaultValues.hasDates ? moment().format(DATE_FORMAT) : null,
      measurementA: this.defaultValues.measurementA,
      measurementB: this.defaultValues.measurementB,
      isUnitMetric: this.defaultValues.isUnitMetric
    };
  }

  public calculate(inputs: CalculationInputs): CalculateResponse {
    if (this.validate(inputs)) {
      const { percentile, chart } = this.mainCalculate(inputs);

      return {
        success: true,
        data: {
          isMale: inputs.isMale,
          percentile,
          chart
        }
      };
    } else {
      return { success: false, error: "An error has ocurred." };
    }
  }

  protected validate(inputs: CalculationInputs): boolean {
    throw new Error("Calculator was not initiated correctly.");
  }
  protected mainCalculate(inputs: CalculationInputs): Dictionary {
    throw new Error("Calculator was not initiated correctly.");
  }
  protected getAPCMaleWHO(): APC[] {
    throw new Error("Calculator was not initiated correctly.");
  }
  protected getAPCFemaleWHO(): APC[] {
    throw new Error("Calculator was not initiated correctly.");
  }
  protected getAzScoreArea(): number[] {
    throw new Error("Calculator was not initiated correctly.");
  }
}
