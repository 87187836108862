/* eslint-disable */
import {
  WHOCalculator,
  CalculationInputs,
  APC,
  Dictionary
} from "./who-calculator";

export class WeightLengthZeroToTwo extends WHOCalculator  {
  
  constructor() {
    super();
    
    this.defaultValues.hasDates = false;
    this.defaultValues.ageRange = [0, 2];
    this.defaultValues.measurementA = 8.3;
    this.defaultValues.measurementB = 70;

    this.labels.name = "Peso - Longitud";
    this.labels.description = "De 0 a 2 años";
    this.labels.measurementAName = "Peso";
    this.labels.measurementAUnit = "kg";
    this.labels.measurementBName = "Longitud";
    this.labels.measurementBUnit = "cm";
  }

  protected validate(inputs: CalculationInputs): boolean {
    if (!inputs.isMale === undefined) return false;
    if (!inputs.isUnitMetric === undefined) return false;
    if (!inputs.measurementA) return false;
    if (!inputs.measurementB) return false;
    return true;
  }

  protected mainCalculate(inputs: CalculationInputs): Dictionary {
    const azScoreArea = this.getAzScoreArea();
    const apc: APC[] = inputs.isMale
      ? this.getAPCMaleWHO()
      : this.getAPCFemaleWHO();
    const q = this.calculatePercentileTables(apc);
    const p = Number(inputs.measurementA);
    const l = Number(inputs.measurementB);
    const w = 110;

    let B = 0;
    let h = 0;
    let F = 0;
    let d = 0;
    let H = 1;
    let z = 0;
    let b = 0;
    let e = 0;
    let t = 0;
    let o = 0;
    let n = 0;
    let E = 0;
    let x = 0;
    let I = 0;
    let a = 0;
    let s = 0;
    let k = 0;
    let j = 0;
    let D = 0;
    let g = 0;

    if (inputs.isUnitMetric) {
      B = l;
      h = l * 0.393701;
      F = p;
      d = p * 2.20462;
    } else {
      B = l / 0.393701;
      h = l;
      F = p / 2.20462;
      d = p;
    }

    if (B >= 45 && B <= w) {
      while (B >= q[H].ageMonths) {
        H = H + 1;
      }
      z = q[H - 1].L;
      b = q[H - 1].M;
      e = q[H - 1].S;
      t = q[H - 1].ageMonths;
      o = (Math.pow(F / b, z) - 1) / (z * e);
      o = o * 100;
      n = Math.round(o);
      if (n < -400) {
        n = -400;
      } else {
        if (n > 400) {
          n = 400;
        }
      }
      E = azScoreArea[n] * 100;
      x = q[H].L;
      I = q[H].M;
      a = q[H].S;
      s = q[H].ageMonths;
      k = (Math.pow(F / I, x) - 1) / (x * a);
      k = k * 100;
      j = Math.round(k);
      if (j < -400) {
        j = -400;
      } else {
        if (j > 400) {
          j = 400;
        }
      }
      D = azScoreArea[j] * 100;
      g = ((B - t) * (D - E)) / (s - t) + E;

      return {
        percentile: g.toFixed(1),
        measurementA: B.toFixed(2),
        measurementB: F.toFixed(2),
        chart: {
          marker: {
            ageMonths: B,
            measure: F
          },
          values: q,
          axes: {
            x: { measure: this.labels.measurementBName, unit: this.labels.measurementBUnit },
            y: { measure: this.labels.measurementAName, unit: this.labels.measurementAUnit }
          }
        }
      };
    }

    return { error: "An error ocurred!" };
  }

  protected calculatePercentileTables(a: APC[]): Dictionary[] {
    const res: Dictionary[] = [];
    for (let i = 0; i < a.length; i++) {
      res[i] = {
        ageMonths: a[i].ageMonths,
        // ageMonths_lb: a[i].ageMonths * 0.393701,
        L: a[i].L,
        M: a[i].M,
        S: a[i].S,
        P5: a[i].M * Math.pow(1 + a[i].L * a[i].S * -1.6448536251337, 1 / a[i].L),
        P10: a[i].M * Math.pow(1 + a[i].L * a[i].S * -1.2815515641402, 1 / a[i].L),
        P25: a[i].M * Math.pow(1 + a[i].L * a[i].S * -0.67448975022342, 1 / a[i].L),
        P50: a[i].M * Math.pow(1 + a[i].L * a[i].S * 0, 1 / a[i].L),
        P75: a[i].M * Math.pow(1 + a[i].L * a[i].S * 0.67448975022342, 1 / a[i].L),
        P90: a[i].M * Math.pow(1 + a[i].L * a[i].S * 1.2815515641402, 1 / a[i].L),
        P95: a[i].M * Math.pow(1 + a[i].L * a[i].S * 1.6448536251337, 1 / a[i].L)
        // P5_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * -1.6448536251337, 1 / a[i].L) * 2.20462,
        // P10_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * -1.2815515641402, 1 / a[i].L) * 2.20462,
        // P25_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * -0.67448975022342, 1 / a[i].L) * 2.20462,
        // P50_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * 0, 1 / a[i].L) * 2.20462,
        // P75_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * 0.67448975022342, 1 / a[i].L) * 2.20462,
        // P90_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * 1.2815515641402, 1 / a[i].L) * 2.20462,
        // P95_lb: a[i].M * Math.pow(1 + a[i].L * a[i].S * 1.6448536251337, 1 / a[i].L) * 2.20462
      };
    }

    return res;
  }

  protected getAPCFemaleWHO(): APC[] {
    return [
      { ageMonths: 45, L: -0.3833, M: 2.4607, S: 0.09029 },
      { ageMonths: 45.5, L: -0.3833, M: 2.5457, S: 0.09033 },
      { ageMonths: 46, L: -0.3833, M: 2.6306, S: 0.09037 },
      { ageMonths: 46.5, L: -0.3833, M: 2.7155, S: 0.0904 },
      { ageMonths: 47, L: -0.3833, M: 2.8007, S: 0.09044 },
      { ageMonths: 47.5, L: -0.3833, M: 2.8867, S: 0.09048 },
      { ageMonths: 48, L: -0.3833, M: 2.9741, S: 0.09052 },
      { ageMonths: 48.5, L: -0.3833, M: 3.0636, S: 0.09056 },
      { ageMonths: 49, L: -0.3833, M: 3.156, S: 0.0906 },
      { ageMonths: 49.5, L: -0.3833, M: 3.252, S: 0.09064 },
      { ageMonths: 50, L: -0.3833, M: 3.3518, S: 0.09068 },
      { ageMonths: 50.5, L: -0.3833, M: 3.4557, S: 0.09072 },
      { ageMonths: 51, L: -0.3833, M: 3.5636, S: 0.09076 },
      { ageMonths: 51.5, L: -0.3833, M: 3.6754, S: 0.0908 },
      { ageMonths: 52, L: -0.3833, M: 3.7911, S: 0.09085 },
      { ageMonths: 52.5, L: -0.3833, M: 3.9105, S: 0.09089 },
      { ageMonths: 53, L: -0.3833, M: 4.0332, S: 0.09093 },
      { ageMonths: 53.5, L: -0.3833, M: 4.1591, S: 0.09098 },
      { ageMonths: 54, L: -0.3833, M: 4.2875, S: 0.09102 },
      { ageMonths: 54.5, L: -0.3833, M: 4.4179, S: 0.09106 },
      { ageMonths: 55, L: -0.3833, M: 4.5498, S: 0.0911 },
      { ageMonths: 55.5, L: -0.3833, M: 4.6827, S: 0.09114 },
      { ageMonths: 56, L: -0.3833, M: 4.8162, S: 0.09118 },
      { ageMonths: 56.5, L: -0.3833, M: 4.95, S: 0.09121 },
      { ageMonths: 57, L: -0.3833, M: 5.0837, S: 0.09125 },
      { ageMonths: 57.5, L: -0.3833, M: 5.2173, S: 0.09128 },
      { ageMonths: 58, L: -0.3833, M: 5.3507, S: 0.0913 },
      { ageMonths: 58.5, L: -0.3833, M: 5.4834, S: 0.09132 },
      { ageMonths: 59, L: -0.3833, M: 5.6151, S: 0.09134 },
      { ageMonths: 59.5, L: -0.3833, M: 5.7454, S: 0.09135 },
      { ageMonths: 60, L: -0.3833, M: 5.8742, S: 0.09136 },
      { ageMonths: 60.5, L: -0.3833, M: 6.0014, S: 0.09137 },
      { ageMonths: 61, L: -0.3833, M: 6.127, S: 0.09137 },
      { ageMonths: 61.5, L: -0.3833, M: 6.2511, S: 0.09136 },
      { ageMonths: 62, L: -0.3833, M: 6.3738, S: 0.09135 },
      { ageMonths: 62.5, L: -0.3833, M: 6.4948, S: 0.09133 },
      { ageMonths: 63, L: -0.3833, M: 6.6144, S: 0.09131 },
      { ageMonths: 63.5, L: -0.3833, M: 6.7328, S: 0.09129 },
      { ageMonths: 64, L: -0.3833, M: 6.8501, S: 0.09126 },
      { ageMonths: 64.5, L: -0.3833, M: 6.9662, S: 0.09123 },
      { ageMonths: 65, L: -0.3833, M: 7.0812, S: 0.09119 },
      { ageMonths: 65.5, L: -0.3833, M: 7.195, S: 0.09115 },
      { ageMonths: 66, L: -0.3833, M: 7.3076, S: 0.0911 },
      { ageMonths: 66.5, L: -0.3833, M: 7.4189, S: 0.09106 },
      { ageMonths: 67, L: -0.3833, M: 7.5288, S: 0.09101 },
      { ageMonths: 67.5, L: -0.3833, M: 7.6375, S: 0.09096 },
      { ageMonths: 68, L: -0.3833, M: 7.7448, S: 0.0909 },
      { ageMonths: 68.5, L: -0.3833, M: 7.8509, S: 0.09085 },
      { ageMonths: 69, L: -0.3833, M: 7.9559, S: 0.09079 },
      { ageMonths: 69.5, L: -0.3833, M: 8.0599, S: 0.09074 },
      { ageMonths: 70, L: -0.3833, M: 8.163, S: 0.09068 },
      { ageMonths: 70.5, L: -0.3833, M: 8.2651, S: 0.09062 },
      { ageMonths: 71, L: -0.3833, M: 8.3666, S: 0.09056 },
      { ageMonths: 71.5, L: -0.3833, M: 8.4676, S: 0.0905 },
      { ageMonths: 72, L: -0.3833, M: 8.5679, S: 0.09043 },
      { ageMonths: 72.5, L: -0.3833, M: 8.6674, S: 0.09037 },
      { ageMonths: 73, L: -0.3833, M: 8.7661, S: 0.09031 },
      { ageMonths: 73.5, L: -0.3833, M: 8.8638, S: 0.09025 },
      { ageMonths: 74, L: -0.3833, M: 8.9601, S: 0.09018 },
      { ageMonths: 74.5, L: -0.3833, M: 9.0552, S: 0.09012 },
      { ageMonths: 75, L: -0.3833, M: 9.149, S: 0.09005 },
      { ageMonths: 75.5, L: -0.3833, M: 9.2418, S: 0.08999 },
      { ageMonths: 76, L: -0.3833, M: 9.3337, S: 0.08992 },
      { ageMonths: 76.5, L: -0.3833, M: 9.4252, S: 0.08985 },
      { ageMonths: 77, L: -0.3833, M: 9.5166, S: 0.08979 },
      { ageMonths: 77.5, L: -0.3833, M: 9.6086, S: 0.08972 },
      { ageMonths: 78, L: -0.3833, M: 9.7015, S: 0.08965 },
      { ageMonths: 78.5, L: -0.3833, M: 9.7957, S: 0.08959 },
      { ageMonths: 79, L: -0.3833, M: 9.8915, S: 0.08952 },
      { ageMonths: 79.5, L: -0.3833, M: 9.9892, S: 0.08946 },
      { ageMonths: 80, L: -0.3833, M: 10.0891, S: 0.0894 },
      { ageMonths: 80.5, L: -0.3833, M: 10.1916, S: 0.08934 },
      { ageMonths: 81, L: -0.3833, M: 10.2965, S: 0.08928 },
      { ageMonths: 81.5, L: -0.3833, M: 10.4041, S: 0.08923 },
      { ageMonths: 82, L: -0.3833, M: 10.514, S: 0.08918 },
      { ageMonths: 82.5, L: -0.3833, M: 10.6263, S: 0.08914 },
      { ageMonths: 83, L: -0.3833, M: 10.741, S: 0.0891 },
      { ageMonths: 83.5, L: -0.3833, M: 10.8578, S: 0.08906 },
      { ageMonths: 84, L: -0.3833, M: 10.9767, S: 0.08903 },
      { ageMonths: 84.5, L: -0.3833, M: 11.0974, S: 0.089 },
      { ageMonths: 85, L: -0.3833, M: 11.2198, S: 0.08898 },
      { ageMonths: 85.5, L: -0.3833, M: 11.3435, S: 0.08897 },
      { ageMonths: 86, L: -0.3833, M: 11.4684, S: 0.08895 },
      { ageMonths: 86.5, L: -0.3833, M: 11.594, S: 0.08895 },
      { ageMonths: 87, L: -0.3833, M: 11.7201, S: 0.08895 },
      { ageMonths: 87.5, L: -0.3833, M: 11.8461, S: 0.08895 },
      { ageMonths: 88, L: -0.3833, M: 11.972, S: 0.08896 },
      { ageMonths: 88.5, L: -0.3833, M: 12.0976, S: 0.08898 },
      { ageMonths: 89, L: -0.3833, M: 12.2229, S: 0.089 },
      { ageMonths: 89.5, L: -0.3833, M: 12.3477, S: 0.08903 },
      { ageMonths: 90, L: -0.3833, M: 12.4723, S: 0.08906 },
      { ageMonths: 90.5, L: -0.3833, M: 12.5965, S: 0.08909 },
      { ageMonths: 91, L: -0.3833, M: 12.7205, S: 0.08913 },
      { ageMonths: 91.5, L: -0.3833, M: 12.8443, S: 0.08918 },
      { ageMonths: 92, L: -0.3833, M: 12.9681, S: 0.08923 },
      { ageMonths: 92.5, L: -0.3833, M: 13.092, S: 0.08928 },
      { ageMonths: 93, L: -0.3833, M: 13.2158, S: 0.08934 },
      { ageMonths: 93.5, L: -0.3833, M: 13.3399, S: 0.08941 },
      { ageMonths: 94, L: -0.3833, M: 13.4643, S: 0.08948 },
      { ageMonths: 94.5, L: -0.3833, M: 13.5892, S: 0.08955 },
      { ageMonths: 95, L: -0.3833, M: 13.7146, S: 0.08963 },
      { ageMonths: 95.5, L: -0.3833, M: 13.8408, S: 0.08972 },
      { ageMonths: 96, L: -0.3833, M: 13.9676, S: 0.08981 },
      { ageMonths: 96.5, L: -0.3833, M: 14.0953, S: 0.0899 },
      { ageMonths: 97, L: -0.3833, M: 14.2239, S: 0.09 },
      { ageMonths: 97.5, L: -0.3833, M: 14.3537, S: 0.0901 },
      { ageMonths: 98, L: -0.3833, M: 14.4848, S: 0.09021 },
      { ageMonths: 98.5, L: -0.3833, M: 14.6174, S: 0.09033 },
      { ageMonths: 99, L: -0.3833, M: 14.7519, S: 0.09044 },
      { ageMonths: 99.5, L: -0.3833, M: 14.8882, S: 0.09057 },
      { ageMonths: 100, L: -0.3833, M: 15.0267, S: 0.09069 },
      { ageMonths: 100.5, L: -0.3833, M: 15.1676, S: 0.09083 },
      { ageMonths: 101, L: -0.3833, M: 15.3108, S: 0.09096 },
      { ageMonths: 101.5, L: -0.3833, M: 15.4564, S: 0.0911 },
      { ageMonths: 102, L: -0.3833, M: 15.6046, S: 0.09125 },
      { ageMonths: 102.5, L: -0.3833, M: 15.7553, S: 0.09139 },
      { ageMonths: 103, L: -0.3833, M: 15.9087, S: 0.09155 },
      { ageMonths: 103.5, L: -0.3833, M: 16.0645, S: 0.0917 },
      { ageMonths: 104, L: -0.3833, M: 16.2229, S: 0.09186 },
      { ageMonths: 104.5, L: -0.3833, M: 16.3837, S: 0.09203 },
      { ageMonths: 105, L: -0.3833, M: 16.547, S: 0.09219 },
      { ageMonths: 105.5, L: -0.3833, M: 16.7129, S: 0.09236 },
      { ageMonths: 106, L: -0.3833, M: 16.8814, S: 0.09254 },
      { ageMonths: 106.5, L: -0.3833, M: 17.0527, S: 0.09271 },
      { ageMonths: 107, L: -0.3833, M: 17.2269, S: 0.09289 },
      { ageMonths: 107.5, L: -0.3833, M: 17.4039, S: 0.09307 },
      { ageMonths: 108, L: -0.3833, M: 17.5839, S: 0.09326 },
      { ageMonths: 108.5, L: -0.3833, M: 17.7668, S: 0.09344 },
      { ageMonths: 109, L: -0.3833, M: 17.9526, S: 0.09363 },
      { ageMonths: 109.5, L: -0.3833, M: 18.1412, S: 0.09382 },
      { ageMonths: 110, L: -0.3833, M: 18.3324, S: 0.09401 }
    ];
  }

  protected getAPCMaleWHO(): APC[] {
    return [
      { ageMonths: 45, L: -0.3521, M: 2.441, S: 0.09182 },
      { ageMonths: 45.5, L: -0.3521, M: 2.5244, S: 0.09153 },
      { ageMonths: 46, L: -0.3521, M: 2.6077, S: 0.09124 },
      { ageMonths: 46.5, L: -0.3521, M: 2.6913, S: 0.09094 },
      { ageMonths: 47, L: -0.3521, M: 2.7755, S: 0.09065 },
      { ageMonths: 47.5, L: -0.3521, M: 2.8609, S: 0.09036 },
      { ageMonths: 48, L: -0.3521, M: 2.948, S: 0.09007 },
      { ageMonths: 48.5, L: -0.3521, M: 3.0377, S: 0.08977 },
      { ageMonths: 49, L: -0.3521, M: 3.1308, S: 0.08948 },
      { ageMonths: 49.5, L: -0.3521, M: 3.2276, S: 0.08919 },
      { ageMonths: 50, L: -0.3521, M: 3.3278, S: 0.0889 },
      { ageMonths: 50.5, L: -0.3521, M: 3.4311, S: 0.08861 },
      { ageMonths: 51, L: -0.3521, M: 3.5376, S: 0.08831 },
      { ageMonths: 51.5, L: -0.3521, M: 3.6477, S: 0.08801 },
      { ageMonths: 52, L: -0.3521, M: 3.762, S: 0.08771 },
      { ageMonths: 52.5, L: -0.3521, M: 3.8814, S: 0.08741 },
      { ageMonths: 53, L: -0.3521, M: 4.006, S: 0.08711 },
      { ageMonths: 53.5, L: -0.3521, M: 4.1354, S: 0.08681 },
      { ageMonths: 54, L: -0.3521, M: 4.2693, S: 0.08651 },
      { ageMonths: 54.5, L: -0.3521, M: 4.4066, S: 0.08621 },
      { ageMonths: 55, L: -0.3521, M: 4.5467, S: 0.08592 },
      { ageMonths: 55.5, L: -0.3521, M: 4.6892, S: 0.08563 },
      { ageMonths: 56, L: -0.3521, M: 4.8338, S: 0.08535 },
      { ageMonths: 56.5, L: -0.3521, M: 4.9796, S: 0.08507 },
      { ageMonths: 57, L: -0.3521, M: 5.1259, S: 0.08481 },
      { ageMonths: 57.5, L: -0.3521, M: 5.2721, S: 0.08455 },
      { ageMonths: 58, L: -0.3521, M: 5.418, S: 0.0843 },
      { ageMonths: 58.5, L: -0.3521, M: 5.5632, S: 0.08406 },
      { ageMonths: 59, L: -0.3521, M: 5.7074, S: 0.08383 },
      { ageMonths: 59.5, L: -0.3521, M: 5.8501, S: 0.08362 },
      { ageMonths: 60, L: -0.3521, M: 5.9907, S: 0.08342 },
      { ageMonths: 60.5, L: -0.3521, M: 6.1284, S: 0.08324 },
      { ageMonths: 61, L: -0.3521, M: 6.2632, S: 0.08308 },
      { ageMonths: 61.5, L: -0.3521, M: 6.3954, S: 0.08292 },
      { ageMonths: 62, L: -0.3521, M: 6.5251, S: 0.08279 },
      { ageMonths: 62.5, L: -0.3521, M: 6.6527, S: 0.08266 },
      { ageMonths: 63, L: -0.3521, M: 6.7786, S: 0.08255 },
      { ageMonths: 63.5, L: -0.3521, M: 6.9028, S: 0.08245 },
      { ageMonths: 64, L: -0.3521, M: 7.0255, S: 0.08236 },
      { ageMonths: 64.5, L: -0.3521, M: 7.1467, S: 0.08229 },
      { ageMonths: 65, L: -0.3521, M: 7.2666, S: 0.08223 },
      { ageMonths: 65.5, L: -0.3521, M: 7.3854, S: 0.08218 },
      { ageMonths: 66, L: -0.3521, M: 7.5034, S: 0.08215 },
      { ageMonths: 66.5, L: -0.3521, M: 7.6206, S: 0.08213 },
      { ageMonths: 67, L: -0.3521, M: 7.737, S: 0.08212 },
      { ageMonths: 67.5, L: -0.3521, M: 7.8526, S: 0.08212 },
      { ageMonths: 68, L: -0.3521, M: 7.9674, S: 0.08214 },
      { ageMonths: 68.5, L: -0.3521, M: 8.0816, S: 0.08216 },
      { ageMonths: 69, L: -0.3521, M: 8.1955, S: 0.08219 },
      { ageMonths: 69.5, L: -0.3521, M: 8.3092, S: 0.08224 },
      { ageMonths: 70, L: -0.3521, M: 8.4227, S: 0.08229 },
      { ageMonths: 70.5, L: -0.3521, M: 8.5358, S: 0.08235 },
      { ageMonths: 71, L: -0.3521, M: 8.648, S: 0.08241 },
      { ageMonths: 71.5, L: -0.3521, M: 8.7594, S: 0.08248 },
      { ageMonths: 72, L: -0.3521, M: 8.8697, S: 0.08254 },
      { ageMonths: 72.5, L: -0.3521, M: 8.9788, S: 0.08262 },
      { ageMonths: 73, L: -0.3521, M: 9.0865, S: 0.08269 },
      { ageMonths: 73.5, L: -0.3521, M: 9.1927, S: 0.08276 },
      { ageMonths: 74, L: -0.3521, M: 9.2974, S: 0.08283 },
      { ageMonths: 74.5, L: -0.3521, M: 9.401, S: 0.08289 },
      { ageMonths: 75, L: -0.3521, M: 9.5032, S: 0.08295 },
      { ageMonths: 75.5, L: -0.3521, M: 9.6041, S: 0.08301 },
      { ageMonths: 76, L: -0.3521, M: 9.7033, S: 0.08307 },
      { ageMonths: 76.5, L: -0.3521, M: 9.8007, S: 0.08311 },
      { ageMonths: 77, L: -0.3521, M: 9.8963, S: 0.08314 },
      { ageMonths: 77.5, L: -0.3521, M: 9.9902, S: 0.08317 },
      { ageMonths: 78, L: -0.3521, M: 10.0827, S: 0.08318 },
      { ageMonths: 78.5, L: -0.3521, M: 10.1741, S: 0.08318 },
      { ageMonths: 79, L: -0.3521, M: 10.2649, S: 0.08316 },
      { ageMonths: 79.5, L: -0.3521, M: 10.3558, S: 0.08313 },
      { ageMonths: 80, L: -0.3521, M: 10.4475, S: 0.08308 },
      { ageMonths: 80.5, L: -0.3521, M: 10.5405, S: 0.08301 },
      { ageMonths: 81, L: -0.3521, M: 10.6352, S: 0.08293 },
      { ageMonths: 81.5, L: -0.3521, M: 10.7322, S: 0.08284 },
      { ageMonths: 82, L: -0.3521, M: 10.8321, S: 0.08273 },
      { ageMonths: 82.5, L: -0.3521, M: 10.935, S: 0.0826 },
      { ageMonths: 83, L: -0.3521, M: 11.0415, S: 0.08246 },
      { ageMonths: 83.5, L: -0.3521, M: 11.1516, S: 0.08231 },
      { ageMonths: 84, L: -0.3521, M: 11.2651, S: 0.08215 },
      { ageMonths: 84.5, L: -0.3521, M: 11.3817, S: 0.08198 },
      { ageMonths: 85, L: -0.3521, M: 11.5007, S: 0.08181 },
      { ageMonths: 85.5, L: -0.3521, M: 11.6218, S: 0.08163 },
      { ageMonths: 86, L: -0.3521, M: 11.7444, S: 0.08145 },
      { ageMonths: 86.5, L: -0.3521, M: 11.8678, S: 0.08128 },
      { ageMonths: 87, L: -0.3521, M: 11.9916, S: 0.08111 },
      { ageMonths: 87.5, L: -0.3521, M: 12.1152, S: 0.08096 },
      { ageMonths: 88, L: -0.3521, M: 12.2382, S: 0.08082 },
      { ageMonths: 88.5, L: -0.3521, M: 12.3603, S: 0.08069 },
      { ageMonths: 89, L: -0.3521, M: 12.4815, S: 0.08058 },
      { ageMonths: 89.5, L: -0.3521, M: 12.6017, S: 0.08048 },
      { ageMonths: 90, L: -0.3521, M: 12.7209, S: 0.08041 },
      { ageMonths: 90.5, L: -0.3521, M: 12.8392, S: 0.08034 },
      { ageMonths: 91, L: -0.3521, M: 12.9569, S: 0.0803 },
      { ageMonths: 91.5, L: -0.3521, M: 13.0742, S: 0.08026 },
      { ageMonths: 92, L: -0.3521, M: 13.191, S: 0.08025 },
      { ageMonths: 92.5, L: -0.3521, M: 13.3075, S: 0.08025 },
      { ageMonths: 93, L: -0.3521, M: 13.4239, S: 0.08026 },
      { ageMonths: 93.5, L: -0.3521, M: 13.5404, S: 0.08029 },
      { ageMonths: 94, L: -0.3521, M: 13.6572, S: 0.08034 },
      { ageMonths: 94.5, L: -0.3521, M: 13.7746, S: 0.0804 },
      { ageMonths: 95, L: -0.3521, M: 13.8928, S: 0.08047 },
      { ageMonths: 95.5, L: -0.3521, M: 14.012, S: 0.08056 },
      { ageMonths: 96, L: -0.3521, M: 14.1325, S: 0.08067 },
      { ageMonths: 96.5, L: -0.3521, M: 14.2544, S: 0.08078 },
      { ageMonths: 97, L: -0.3521, M: 14.3782, S: 0.08092 },
      { ageMonths: 97.5, L: -0.3521, M: 14.5038, S: 0.08106 },
      { ageMonths: 98, L: -0.3521, M: 14.6316, S: 0.08122 },
      { ageMonths: 98.5, L: -0.3521, M: 14.7614, S: 0.08139 },
      { ageMonths: 99, L: -0.3521, M: 14.8934, S: 0.08157 },
      { ageMonths: 99.5, L: -0.3521, M: 15.0275, S: 0.08177 },
      { ageMonths: 100, L: -0.3521, M: 15.1637, S: 0.08198 },
      { ageMonths: 100.5, L: -0.3521, M: 15.3018, S: 0.0822 },
      { ageMonths: 101, L: -0.3521, M: 15.4419, S: 0.08243 },
      { ageMonths: 101.5, L: -0.3521, M: 15.5838, S: 0.08267 },
      { ageMonths: 102, L: -0.3521, M: 15.7276, S: 0.08292 },
      { ageMonths: 102.5, L: -0.3521, M: 15.8732, S: 0.08317 },
      { ageMonths: 103, L: -0.3521, M: 16.0206, S: 0.08343 },
      { ageMonths: 103.5, L: -0.3521, M: 16.1697, S: 0.0837 },
      { ageMonths: 104, L: -0.3521, M: 16.3204, S: 0.08397 },
      { ageMonths: 104.5, L: -0.3521, M: 16.4728, S: 0.08425 },
      { ageMonths: 105, L: -0.3521, M: 16.6268, S: 0.08453 },
      { ageMonths: 105.5, L: -0.3521, M: 16.7826, S: 0.08481 },
      { ageMonths: 106, L: -0.3521, M: 16.9401, S: 0.0851 },
      { ageMonths: 106.5, L: -0.3521, M: 17.0995, S: 0.08539 },
      { ageMonths: 107, L: -0.3521, M: 17.2607, S: 0.08568 },
      { ageMonths: 107.5, L: -0.3521, M: 17.4237, S: 0.08599 },
      { ageMonths: 108, L: -0.3521, M: 17.5885, S: 0.08629 },
      { ageMonths: 108.5, L: -0.3521, M: 17.7553, S: 0.0866 },
      { ageMonths: 109, L: -0.3521, M: 17.9242, S: 0.08691 },
      { ageMonths: 109.5, L: -0.3521, M: 18.0954, S: 0.08723 },
      { ageMonths: 110, L: -0.3521, M: 18.2689, S: 0.08755 }
    ];
  }

  protected getAzScoreArea(): number[] {
    const arr = [];

    arr[-400] = 0.000031686;
    arr[-399] = 0.0000330518;
    arr[-398] = 0.0000344732;
    arr[-397] = 0.0000359523;
    arr[-396] = 0.0000374913;
    arr[-395] = 0.0000390925;
    arr[-394] = 0.0000407581;
    arr[-393] = 0.0000424907;
    arr[-392] = 0.0000442927;
    arr[-391] = 0.0000461668;
    arr[-390] = 0.0000481155;
    arr[-389] = 0.0000501418;
    arr[-388] = 0.0000522484;
    arr[-387] = 0.0000544383;
    arr[-386] = 0.0000567147;
    arr[-385] = 0.0000590806;
    arr[-384] = 0.0000615394;
    arr[-383] = 0.0000640944;
    arr[-382] = 0.0000667491;
    arr[-381] = 0.0000695072;
    arr[-380] = 0.0000723724;
    arr[-379] = 0.0000753486;
    arr[-378] = 0.0000784397;
    arr[-377] = 0.0000816499;
    arr[-376] = 0.0000849834;
    arr[-375] = 0.0000884446;
    arr[-374] = 0.000092038;
    arr[-373] = 0.0000957684;
    arr[-372] = 0.0000996405;
    arr[-371] = 0.000103659;
    arr[-370] = 0.00010783;
    arr[-369] = 0.000112158;
    arr[-368] = 0.000116649;
    arr[-367] = 0.000121308;
    arr[-366] = 0.000126141;
    arr[-365] = 0.000131154;
    arr[-364] = 0.000136353;
    arr[-363] = 0.000141746;
    arr[-362] = 0.000147337;
    arr[-361] = 0.000153135;
    arr[-360] = 0.000159146;
    arr[-359] = 0.000165377;
    arr[-358] = 0.000171836;
    arr[-357] = 0.00017853;
    arr[-356] = 0.000185467;
    arr[-355] = 0.000192656;
    arr[-354] = 0.000200105;
    arr[-353] = 0.000207822;
    arr[-352] = 0.000215816;
    arr[-351] = 0.000224097;
    arr[-350] = 0.000232673;
    arr[-349] = 0.000241555;
    arr[-348] = 0.000250753;
    arr[-347] = 0.000260276;
    arr[-346] = 0.000270135;
    arr[-345] = 0.000280341;
    arr[-344] = 0.000290906;
    arr[-343] = 0.00030184;
    arr[-342] = 0.000313156;
    arr[-341] = 0.000324865;
    arr[-340] = 0.000336981;
    arr[-339] = 0.000349515;
    arr[-338] = 0.000362482;
    arr[-337] = 0.000375895;
    arr[-336] = 0.000389767;
    arr[-335] = 0.000404113;
    arr[-334] = 0.000418948;
    arr[-333] = 0.000434286;
    arr[-332] = 0.000450144;
    arr[-331] = 0.000466538;
    arr[-330] = 0.000483483;
    arr[-329] = 0.000500996;
    arr[-328] = 0.000519095;
    arr[-327] = 0.000537798;
    arr[-326] = 0.000557122;
    arr[-325] = 0.000577086;
    arr[-324] = 0.000597711;
    arr[-323] = 0.000619014;
    arr[-322] = 0.000641016;
    arr[-321] = 0.000663738;
    arr[-320] = 0.000687202;
    arr[-319] = 0.000711429;
    arr[-318] = 0.00073644;
    arr[-317] = 0.00076226;
    arr[-316] = 0.000788912;
    arr[-315] = 0.000816419;
    arr[-314] = 0.000844806;
    arr[-313] = 0.000874099;
    arr[-312] = 0.000904323;
    arr[-311] = 0.000935504;
    arr[-310] = 0.000967671;
    arr[-309] = 0.001000851;
    arr[-308] = 0.001035071;
    arr[-307] = 0.001070363;
    arr[-306] = 0.001106754;
    arr[-305] = 0.001144276;
    arr[-304] = 0.00118296;
    arr[-303] = 0.001222838;
    arr[-302] = 0.001263943;
    arr[-301] = 0.001306308;
    arr[-300] = 0.001349967;
    arr[-299] = 0.001394956;
    arr[-298] = 0.001441311;
    arr[-297] = 0.001489068;
    arr[-296] = 0.001538264;
    arr[-295] = 0.001588938;
    arr[-294] = 0.001641129;
    arr[-293] = 0.001694878;
    arr[-292] = 0.001750225;
    arr[-291] = 0.001807211;
    arr[-290] = 0.00186588;
    arr[-289] = 0.001926276;
    arr[-288] = 0.001988442;
    arr[-287] = 0.002052424;
    arr[-286] = 0.00211827;
    arr[-285] = 0.002186026;
    arr[-284] = 0.00225574;
    arr[-283] = 0.002327463;
    arr[-282] = 0.002401244;
    arr[-281] = 0.002477136;
    arr[-280] = 0.002555191;
    arr[-279] = 0.002635461;
    arr[-278] = 0.002718003;
    arr[-277] = 0.002802872;
    arr[-276] = 0.002890125;
    arr[-275] = 0.002979819;
    arr[-274] = 0.003072013;
    arr[-273] = 0.003166769;
    arr[-272] = 0.003264148;
    arr[-271] = 0.003364211;
    arr[-270] = 0.003467023;
    arr[-269] = 0.003572649;
    arr[-268] = 0.003681155;
    arr[-267] = 0.003792607;
    arr[-266] = 0.003907076;
    arr[-265] = 0.004024631;
    arr[-264] = 0.004145342;
    arr[-263] = 0.004269282;
    arr[-262] = 0.004396526;
    arr[-261] = 0.004527147;
    arr[-260] = 0.004661222;
    arr[-259] = 0.004798829;
    arr[-258] = 0.004940046;
    arr[-257] = 0.005084954;
    arr[-256] = 0.005233635;
    arr[-255] = 0.00538617;
    arr[-254] = 0.005542646;
    arr[-253] = 0.005703147;
    arr[-252] = 0.00586776;
    arr[-251] = 0.006036575;
    arr[-250] = 0.00620968;
    arr[-249] = 0.006387167;
    arr[-248] = 0.006569129;
    arr[-247] = 0.006755661;
    arr[-246] = 0.006946857;
    arr[-245] = 0.007142815;
    arr[-244] = 0.007343633;
    arr[-243] = 0.007549411;
    arr[-242] = 0.007760251;
    arr[-241] = 0.007976255;
    arr[-240] = 0.008197529;
    arr[-239] = 0.008424177;
    arr[-238] = 0.008656308;
    arr[-237] = 0.008894029;
    arr[-236] = 0.009137452;
    arr[-235] = 0.009386687;
    arr[-234] = 0.00964185;
    arr[-233] = 0.009903053;
    arr[-232] = 0.010170414;
    arr[-231] = 0.01044405;
    arr[-230] = 0.010724081;
    arr[-229] = 0.011010627;
    arr[-228] = 0.011303811;
    arr[-227] = 0.011603756;
    arr[-226] = 0.011910588;
    arr[-225] = 0.012224433;
    arr[-224] = 0.01254542;
    arr[-223] = 0.012873678;
    arr[-222] = 0.013209339;
    arr[-221] = 0.013552534;
    arr[-220] = 0.013903399;
    arr[-219] = 0.014262068;
    arr[-218] = 0.014628679;
    arr[-217] = 0.015003369;
    arr[-216] = 0.01538628;
    arr[-215] = 0.015777551;
    arr[-214] = 0.016177325;
    arr[-213] = 0.016585747;
    arr[-212] = 0.017002962;
    arr[-211] = 0.017429116;
    arr[-210] = 0.017864357;
    arr[-209] = 0.018308836;
    arr[-208] = 0.018762701;
    arr[-207] = 0.019226106;
    arr[-206] = 0.019699203;
    arr[-205] = 0.020182148;
    arr[-204] = 0.020675095;
    arr[-203] = 0.021178201;
    arr[-202] = 0.021691624;
    arr[-201] = 0.022215525;
    arr[-200] = 0.022750062;
    arr[-199] = 0.023295398;
    arr[-198] = 0.023851694;
    arr[-197] = 0.024419115;
    arr[-196] = 0.024997825;
    arr[-195] = 0.02558799;
    arr[-194] = 0.026189776;
    arr[-193] = 0.02680335;
    arr[-192] = 0.027428881;
    arr[-191] = 0.028066539;
    arr[-190] = 0.028716493;
    arr[-189] = 0.029378914;
    arr[-188] = 0.030053974;
    arr[-187] = 0.030741845;
    arr[-186] = 0.0314427;
    arr[-185] = 0.032156713;
    arr[-184] = 0.032884058;
    arr[-183] = 0.033624911;
    arr[-182] = 0.034379445;
    arr[-181] = 0.035147838;
    arr[-180] = 0.035930266;
    arr[-179] = 0.036726904;
    arr[-178] = 0.037537931;
    arr[-177] = 0.038363523;
    arr[-176] = 0.039203858;
    arr[-175] = 0.040059114;
    arr[-174] = 0.040929468;
    arr[-173] = 0.041815099;
    arr[-172] = 0.042716185;
    arr[-171] = 0.043632903;
    arr[-170] = 0.044565432;
    arr[-169] = 0.045513949;
    arr[-168] = 0.046478632;
    arr[-167] = 0.047459659;
    arr[-166] = 0.048457206;
    arr[-165] = 0.049471451;
    arr[-164] = 0.050502569;
    arr[-163] = 0.051550737;
    arr[-162] = 0.05261613;
    arr[-161] = 0.053698923;
    arr[-160] = 0.054799289;
    arr[-159] = 0.055917403;
    arr[-158] = 0.057053437;
    arr[-157] = 0.058207562;
    arr[-156] = 0.05937995;
    arr[-155] = 0.060570771;
    arr[-154] = 0.061780193;
    arr[-153] = 0.063008383;
    arr[-152] = 0.06425551;
    arr[-151] = 0.065521737;
    arr[-150] = 0.066807229;
    arr[-149] = 0.068112148;
    arr[-148] = 0.069436656;
    arr[-147] = 0.070780913;
    arr[-146] = 0.072145075;
    arr[-145] = 0.0735293;
    arr[-144] = 0.074933743;
    arr[-143] = 0.076358555;
    arr[-142] = 0.077803888;
    arr[-141] = 0.079269891;
    arr[-140] = 0.080756711;
    arr[-139] = 0.082264493;
    arr[-138] = 0.083793378;
    arr[-137] = 0.085343508;
    arr[-136] = 0.086915021;
    arr[-135] = 0.088508052;
    arr[-134] = 0.090122734;
    arr[-133] = 0.091759198;
    arr[-132] = 0.093417573;
    arr[-131] = 0.095097982;
    arr[-130] = 0.096800549;
    arr[-129] = 0.098525394;
    arr[-128] = 0.100272634;
    arr[-127] = 0.102042381;
    arr[-126] = 0.103834747;
    arr[-125] = 0.105649839;
    arr[-124] = 0.107487762;
    arr[-123] = 0.109348617;
    arr[-122] = 0.111232501;
    arr[-121] = 0.113139509;
    arr[-120] = 0.115069732;
    arr[-119] = 0.117023256;
    arr[-118] = 0.119000166;
    arr[-117] = 0.121000541;
    arr[-116] = 0.123024458;
    arr[-115] = 0.125071989;
    arr[-114] = 0.127143201;
    arr[-113] = 0.129238161;
    arr[-112] = 0.131356927;
    arr[-111] = 0.133499557;
    arr[-110] = 0.135666102;
    arr[-109] = 0.13785661;
    arr[-108] = 0.140071125;
    arr[-107] = 0.142309686;
    arr[-106] = 0.144572328;
    arr[-105] = 0.146859081;
    arr[-104] = 0.149169971;
    arr[-103] = 0.15150502;
    arr[-102] = 0.153864244;
    arr[-101] = 0.156247655;
    arr[-100] = 0.15865526;
    arr[-99] = 0.161087061;
    arr[-98] = 0.163543057;
    arr[-97] = 0.16602324;
    arr[-96] = 0.168527597;
    arr[-95] = 0.171056112;
    arr[-94] = 0.173608762;
    arr[-93] = 0.17618552;
    arr[-92] = 0.178786354;
    arr[-91] = 0.181411225;
    arr[-90] = 0.184060092;
    arr[-89] = 0.186732906;
    arr[-88] = 0.189429614;
    arr[-87] = 0.192150158;
    arr[-86] = 0.194894473;
    arr[-85] = 0.197662492;
    arr[-84] = 0.200454139;
    arr[-83] = 0.203269335;
    arr[-82] = 0.206107994;
    arr[-81] = 0.208970026;
    arr[-80] = 0.211855334;
    arr[-79] = 0.214763817;
    arr[-78] = 0.217695369;
    arr[-77] = 0.220649876;
    arr[-76] = 0.223627221;
    arr[-75] = 0.22662728;
    arr[-74] = 0.229649924;
    arr[-73] = 0.232695018;
    arr[-72] = 0.235762424;
    arr[-71] = 0.238851994;
    arr[-70] = 0.241963578;
    arr[-69] = 0.245097021;
    arr[-68] = 0.248252158;
    arr[-67] = 0.251428824;
    arr[-66] = 0.254626846;
    arr[-65] = 0.257846044;
    arr[-64] = 0.261086235;
    arr[-63] = 0.26434723;
    arr[-62] = 0.267628834;
    arr[-61] = 0.270930848;
    arr[-60] = 0.274253065;
    arr[-59] = 0.277595276;
    arr[-58] = 0.280957264;
    arr[-57] = 0.284338808;
    arr[-56] = 0.287739682;
    arr[-55] = 0.291159655;
    arr[-54] = 0.294598489;
    arr[-53] = 0.298055944;
    arr[-52] = 0.301531771;
    arr[-51] = 0.305025719;
    arr[-50] = 0.308537533;
    arr[-49] = 0.312066949;
    arr[-48] = 0.315613701;
    arr[-47] = 0.319177519;
    arr[-46] = 0.322758126;
    arr[-45] = 0.326355241;
    arr[-44] = 0.32996858;
    arr[-43] = 0.333597852;
    arr[-42] = 0.337242763;
    arr[-41] = 0.340903014;
    arr[-40] = 0.344578303;
    arr[-39] = 0.348268323;
    arr[-38] = 0.35197276;
    arr[-37] = 0.355691301;
    arr[-36] = 0.359423626;
    arr[-35] = 0.36316941;
    arr[-34] = 0.366928327;
    arr[-33] = 0.370700045;
    arr[-32] = 0.37448423;
    arr[-31] = 0.378280543;
    arr[-30] = 0.382088643;
    arr[-29] = 0.385908182;
    arr[-28] = 0.389738814;
    arr[-27] = 0.393580186;
    arr[-26] = 0.397431943;
    arr[-25] = 0.401293726;
    arr[-24] = 0.405165176;
    arr[-23] = 0.409045927;
    arr[-22] = 0.412935613;
    arr[-21] = 0.416833866;
    arr[-20] = 0.420740313;
    arr[-19] = 0.42465458;
    arr[-18] = 0.428576291;
    arr[-17] = 0.432505067;
    arr[-16] = 0.436440527;
    arr[-15] = 0.440382288;
    arr[-14] = 0.444329967;
    arr[-13] = 0.448283177;
    arr[-12] = 0.45224153;
    arr[-11] = 0.456204636;
    arr[-10] = 0.460172104;
    arr[-9] = 0.464143544;
    arr[-8] = 0.46811856;
    arr[-7] = 0.47209676;
    arr[-6] = 0.476077747;
    arr[-5] = 0.480061127;
    arr[-4] = 0.484046501;
    arr[-3] = 0.488033473;
    arr[-2] = 0.492021646;
    arr[-1] = 0.496010621;
    arr[0] = 0.5;
    arr[1] = 0.503989379;
    arr[2] = 0.507978354;
    arr[3] = 0.511966527;
    arr[4] = 0.515953499;
    arr[5] = 0.519938873;
    arr[6] = 0.523922253;
    arr[7] = 0.52790324;
    arr[8] = 0.53188144;
    arr[9] = 0.535856456;
    arr[10] = 0.539827896;
    arr[11] = 0.543795364;
    arr[12] = 0.54775847;
    arr[13] = 0.551716823;
    arr[14] = 0.555670033;
    arr[15] = 0.559617712;
    arr[16] = 0.563559473;
    arr[17] = 0.567494933;
    arr[18] = 0.571423709;
    arr[19] = 0.57534542;
    arr[20] = 0.579259687;
    arr[21] = 0.583166134;
    arr[22] = 0.587064387;
    arr[23] = 0.590954073;
    arr[24] = 0.594834824;
    arr[25] = 0.598706274;
    arr[26] = 0.602568057;
    arr[27] = 0.606419814;
    arr[28] = 0.610261186;
    arr[29] = 0.614091818;
    arr[30] = 0.617911357;
    arr[31] = 0.621719457;
    arr[32] = 0.62551577;
    arr[33] = 0.629299955;
    arr[34] = 0.633071673;
    arr[35] = 0.63683059;
    arr[36] = 0.640576374;
    arr[37] = 0.644308699;
    arr[38] = 0.64802724;
    arr[39] = 0.651731677;
    arr[40] = 0.655421697;
    arr[41] = 0.659096986;
    arr[42] = 0.662757237;
    arr[43] = 0.666402148;
    arr[44] = 0.67003142;
    arr[45] = 0.673644759;
    arr[46] = 0.677241874;
    arr[47] = 0.680822481;
    arr[48] = 0.684386299;
    arr[49] = 0.687933051;
    arr[50] = 0.691462467;
    arr[51] = 0.694974281;
    arr[52] = 0.698468229;
    arr[53] = 0.701944056;
    arr[54] = 0.705401511;
    arr[55] = 0.708840345;
    arr[56] = 0.712260318;
    arr[57] = 0.715661192;
    arr[58] = 0.719042736;
    arr[59] = 0.722404724;
    arr[60] = 0.725746935;
    arr[61] = 0.729069152;
    arr[62] = 0.732371166;
    arr[63] = 0.73565277;
    arr[64] = 0.738913765;
    arr[65] = 0.742153956;
    arr[66] = 0.745373154;
    arr[67] = 0.748571176;
    arr[68] = 0.751747842;
    arr[69] = 0.754902979;
    arr[70] = 0.758036422;
    arr[71] = 0.761148006;
    arr[72] = 0.764237576;
    arr[73] = 0.767304982;
    arr[74] = 0.770350076;
    arr[75] = 0.77337272;
    arr[76] = 0.776372779;
    arr[77] = 0.779350124;
    arr[78] = 0.782304631;
    arr[79] = 0.785236183;
    arr[80] = 0.788144666;
    arr[81] = 0.791029974;
    arr[82] = 0.793892006;
    arr[83] = 0.796730665;
    arr[84] = 0.799545861;
    arr[85] = 0.802337508;
    arr[86] = 0.805105527;
    arr[87] = 0.807849842;
    arr[88] = 0.810570386;
    arr[89] = 0.813267094;
    arr[90] = 0.815939908;
    arr[91] = 0.818588775;
    arr[92] = 0.821213646;
    arr[93] = 0.82381448;
    arr[94] = 0.826391238;
    arr[95] = 0.828943888;
    arr[96] = 0.831472403;
    arr[97] = 0.83397676;
    arr[98] = 0.836456943;
    arr[99] = 0.838912939;
    arr[100] = 0.84134474;
    arr[101] = 0.843752345;
    arr[102] = 0.846135756;
    arr[103] = 0.84849498;
    arr[104] = 0.850830029;
    arr[105] = 0.853140919;
    arr[106] = 0.855427672;
    arr[107] = 0.857690314;
    arr[108] = 0.859928875;
    arr[109] = 0.86214339;
    arr[110] = 0.864333898;
    arr[111] = 0.866500443;
    arr[112] = 0.868643073;
    arr[113] = 0.870761839;
    arr[114] = 0.872856799;
    arr[115] = 0.874928011;
    arr[116] = 0.876975542;
    arr[117] = 0.878999459;
    arr[118] = 0.880999834;
    arr[119] = 0.882976744;
    arr[120] = 0.884930268;
    arr[121] = 0.886860491;
    arr[122] = 0.888767499;
    arr[123] = 0.890651383;
    arr[124] = 0.892512238;
    arr[125] = 0.894350161;
    arr[126] = 0.896165253;
    arr[127] = 0.897957619;
    arr[128] = 0.899727366;
    arr[129] = 0.901474606;
    arr[130] = 0.903199451;
    arr[131] = 0.904902018;
    arr[132] = 0.906582427;
    arr[133] = 0.908240802;
    arr[134] = 0.909877266;
    arr[135] = 0.911491948;
    arr[136] = 0.913084979;
    arr[137] = 0.914656492;
    arr[138] = 0.916206622;
    arr[139] = 0.917735507;
    arr[140] = 0.919243289;
    arr[141] = 0.920730109;
    arr[142] = 0.922196112;
    arr[143] = 0.923641445;
    arr[144] = 0.925066257;
    arr[145] = 0.9264707;
    arr[146] = 0.927854925;
    arr[147] = 0.929219087;
    arr[148] = 0.930563344;
    arr[149] = 0.931887852;
    arr[150] = 0.933192771;
    arr[151] = 0.934478263;
    arr[152] = 0.93574449;
    arr[153] = 0.936991617;
    arr[154] = 0.938219807;
    arr[155] = 0.939429229;
    arr[156] = 0.94062005;
    arr[157] = 0.941792438;
    arr[158] = 0.942946563;
    arr[159] = 0.944082597;
    arr[160] = 0.945200711;
    arr[161] = 0.946301077;
    arr[162] = 0.94738387;
    arr[163] = 0.948449263;
    arr[164] = 0.949497431;
    arr[165] = 0.950528549;
    arr[166] = 0.951542794;
    arr[167] = 0.952540341;
    arr[168] = 0.953521368;
    arr[169] = 0.954486051;
    arr[170] = 0.955434568;
    arr[171] = 0.956367097;
    arr[172] = 0.957283815;
    arr[173] = 0.958184901;
    arr[174] = 0.959070532;
    arr[175] = 0.959940886;
    arr[176] = 0.960796142;
    arr[177] = 0.961636477;
    arr[178] = 0.962462069;
    arr[179] = 0.963273096;
    arr[180] = 0.964069734;
    arr[181] = 0.964852162;
    arr[182] = 0.965620555;
    arr[183] = 0.966375089;
    arr[184] = 0.967115942;
    arr[185] = 0.967843287;
    arr[186] = 0.9685573;
    arr[187] = 0.969258155;
    arr[188] = 0.969946026;
    arr[189] = 0.970621086;
    arr[190] = 0.971283507;
    arr[191] = 0.971933461;
    arr[192] = 0.972571119;
    arr[193] = 0.97319665;
    arr[194] = 0.973810224;
    arr[195] = 0.97441201;
    arr[196] = 0.975002175;
    arr[197] = 0.975580885;
    arr[198] = 0.976148306;
    arr[199] = 0.976704602;
    arr[200] = 0.977249938;
    arr[201] = 0.977784475;
    arr[202] = 0.978308376;
    arr[203] = 0.978821799;
    arr[204] = 0.979324905;
    arr[205] = 0.979817852;
    arr[206] = 0.980300797;
    arr[207] = 0.980773894;
    arr[208] = 0.981237299;
    arr[209] = 0.981691164;
    arr[210] = 0.982135643;
    arr[211] = 0.982570884;
    arr[212] = 0.982997038;
    arr[213] = 0.983414253;
    arr[214] = 0.983822675;
    arr[215] = 0.984222449;
    arr[216] = 0.98461372;
    arr[217] = 0.984996631;
    arr[218] = 0.985371321;
    arr[219] = 0.985737932;
    arr[220] = 0.986096601;
    arr[221] = 0.986447466;
    arr[222] = 0.986790661;
    arr[223] = 0.987126322;
    arr[224] = 0.98745458;
    arr[225] = 0.987775567;
    arr[226] = 0.988089412;
    arr[227] = 0.988396244;
    arr[228] = 0.988696189;
    arr[229] = 0.988989373;
    arr[230] = 0.989275919;
    arr[231] = 0.98955595;
    arr[232] = 0.989829586;
    arr[233] = 0.990096947;
    arr[234] = 0.99035815;
    arr[235] = 0.990613313;
    arr[236] = 0.990862548;
    arr[237] = 0.991105971;
    arr[238] = 0.991343692;
    arr[239] = 0.991575823;
    arr[240] = 0.991802471;
    arr[241] = 0.992023745;
    arr[242] = 0.992239749;
    arr[243] = 0.992450589;
    arr[244] = 0.992656367;
    arr[245] = 0.992857185;
    arr[246] = 0.993053143;
    arr[247] = 0.993244339;
    arr[248] = 0.993430871;
    arr[249] = 0.993612833;
    arr[250] = 0.99379032;
    arr[251] = 0.993963425;
    arr[252] = 0.99413224;
    arr[253] = 0.994296853;
    arr[254] = 0.994457354;
    arr[255] = 0.99461383;
    arr[256] = 0.994766365;
    arr[257] = 0.994915046;
    arr[258] = 0.995059954;
    arr[259] = 0.995201171;
    arr[260] = 0.995338778;
    arr[261] = 0.995472853;
    arr[262] = 0.995603474;
    arr[263] = 0.995730718;
    arr[264] = 0.995854658;
    arr[265] = 0.995975369;
    arr[266] = 0.996092924;
    arr[267] = 0.996207393;
    arr[268] = 0.996318845;
    arr[269] = 0.996427351;
    arr[270] = 0.996532977;
    arr[271] = 0.996635789;
    arr[272] = 0.996735852;
    arr[273] = 0.996833231;
    arr[274] = 0.996927987;
    arr[275] = 0.997020181;
    arr[276] = 0.997109875;
    arr[277] = 0.997197128;
    arr[278] = 0.997281997;
    arr[279] = 0.997364539;
    arr[280] = 0.997444809;
    arr[281] = 0.997522864;
    arr[282] = 0.997598756;
    arr[283] = 0.997672537;
    arr[284] = 0.99774426;
    arr[285] = 0.997813974;
    arr[286] = 0.99788173;
    arr[287] = 0.997947576;
    arr[288] = 0.998011558;
    arr[289] = 0.998073724;
    arr[290] = 0.99813412;
    arr[291] = 0.998192789;
    arr[292] = 0.998249775;
    arr[293] = 0.998305122;
    arr[294] = 0.998358871;
    arr[295] = 0.998411062;
    arr[296] = 0.998461736;
    arr[297] = 0.998510932;
    arr[298] = 0.998558689;
    arr[299] = 0.998605044;
    arr[300] = 0.998650033;
    arr[301] = 0.998693692;
    arr[302] = 0.998736057;
    arr[303] = 0.998777162;
    arr[304] = 0.99881704;
    arr[305] = 0.998855724;
    arr[306] = 0.998893246;
    arr[307] = 0.998929637;
    arr[308] = 0.998964929;
    arr[309] = 0.998999149;
    arr[310] = 0.999032329;
    arr[311] = 0.999064496;
    arr[312] = 0.999095677;
    arr[313] = 0.999125901;
    arr[314] = 0.999155194;
    arr[315] = 0.999183581;
    arr[316] = 0.999211088;
    arr[317] = 0.99923774;
    arr[318] = 0.99926356;
    arr[319] = 0.999288571;
    arr[320] = 0.999312798;
    arr[321] = 0.999336262;
    arr[322] = 0.999358984;
    arr[323] = 0.999380986;
    arr[324] = 0.999402289;
    arr[325] = 0.999422914;
    arr[326] = 0.999442878;
    arr[327] = 0.999462202;
    arr[328] = 0.999480905;
    arr[329] = 0.999499004;
    arr[330] = 0.999516517;
    arr[331] = 0.999533462;
    arr[332] = 0.999549856;
    arr[333] = 0.999565714;
    arr[334] = 0.999581052;
    arr[335] = 0.999595887;
    arr[336] = 0.999610233;
    arr[337] = 0.999624105;
    arr[338] = 0.999637518;
    arr[339] = 0.999650485;
    arr[340] = 0.999663019;
    arr[341] = 0.999675135;
    arr[342] = 0.999686844;
    arr[343] = 0.99969816;
    arr[344] = 0.999709094;
    arr[345] = 0.999719659;
    arr[346] = 0.999729865;
    arr[347] = 0.999739724;
    arr[348] = 0.999749247;
    arr[349] = 0.999758445;
    arr[350] = 0.999767327;
    arr[351] = 0.999775903;
    arr[352] = 0.999784184;
    arr[353] = 0.999792178;
    arr[354] = 0.999799895;
    arr[355] = 0.999807344;
    arr[356] = 0.999814533;
    arr[357] = 0.99982147;
    arr[358] = 0.999828164;
    arr[359] = 0.999834623;
    arr[360] = 0.999840854;
    arr[361] = 0.999846865;
    arr[362] = 0.999852663;
    arr[363] = 0.999858254;
    arr[364] = 0.999863647;
    arr[365] = 0.999868846;
    arr[366] = 0.999873859;
    arr[367] = 0.999878692;
    arr[368] = 0.999883351;
    arr[369] = 0.999887842;
    arr[370] = 0.99989217;
    arr[371] = 0.999896341;
    arr[372] = 0.999900359;
    arr[373] = 0.999904232;
    arr[374] = 0.999907962;
    arr[375] = 0.999911555;
    arr[376] = 0.999915017;
    arr[377] = 0.99991835;
    arr[378] = 0.99992156;
    arr[379] = 0.999924651;
    arr[380] = 0.999927628;
    arr[381] = 0.999930493;
    arr[382] = 0.999933251;
    arr[383] = 0.999935906;
    arr[384] = 0.999938461;
    arr[385] = 0.999940919;
    arr[386] = 0.999943285;
    arr[387] = 0.999945562;
    arr[388] = 0.999947752;
    arr[389] = 0.999949858;
    arr[390] = 0.999951884;
    arr[391] = 0.999953833;
    arr[392] = 0.999955707;
    arr[393] = 0.999957509;
    arr[394] = 0.999959242;
    arr[395] = 0.999960908;
    arr[396] = 0.999962509;
    arr[397] = 0.999964048;
    arr[398] = 0.999965527;
    arr[399] = 0.999966948;
    arr[400] = 0.999968314;

    return arr;
  }

}
