import { WeightAgeZeroToFive } from "./weight-age-zero-to-five";
import { HeightAgeTwoToFive } from "./height-age-two-to-five";
import { WeightHeightTwoToFive } from "./weight-height-two-to-five";
import { WeightLengthZeroToTwo } from "./weight-length-zero-to-two";
import { LenghtAgeZeroToTwo } from "./lenght-age-zero-to-two";
import { HeadCircumferenceAgeZeroToFive } from "./head-circumference-age-zero-to-five";

export class CalculatorFactory {

  public static getWHOCalculator(slug: string) {
    const calculatorList: any = {
      "weight-age-zero-to-five": WeightAgeZeroToFive,
      "height-age-two-to-five": HeightAgeTwoToFive,
      "weight-height-two-to-five": WeightHeightTwoToFive,
      "weight-length-zero-to-two": WeightLengthZeroToTwo,
      "lenght-age-zero-to-two": LenghtAgeZeroToTwo,
      "head-circumference-age-zero-to-five": HeadCircumferenceAgeZeroToFive,
    };

    return calculatorList[slug];
  }

}
