<template>
  <section class="calculator-banner py-10 bg-white">
    <div class="container text-center">
      <div class="font-display font-bold text-3xl text-primary leading-6">
        Calculadoras de creciemiento para el niño sano.
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.calculator-banner {
  box-shadow: inset 0px 12px 12px -8px rgba(0,0,0,0.2);
  background-image: url('/img/breastfeeding-warning-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>